import React, { useState, ChangeEvent, useEffect } from 'react';
import './App.css';
import MapComponent from './MapComponent';
import NavbarComponent from './NavbarComponent';
import 'firebase/firestore'; // Importuj potrzebne usługi Firebase


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc, getDoc, initializeFirestore } from 'firebase/firestore';
import CookieBanner from './CookieBanner';



const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const db = getFirestore(app);

const getUsers = async () => {
  const querySnapshot = await getDocs(collection(db, 'uzytkownicy'));
  querySnapshot.forEach((doc) => {
    console.log(doc.id, ' => ', doc.data());
  })
};

const addUser = async () => {
  const docRef = await addDoc(collection(db, 'uzytkownicy'), {
    imie: "Anna",
    nazwisko: "Malinowska"
  });
};

function App() {

  const [selectedOption, setSelectedOption] = useState<string>(''); // Stan dla wybranej gminy

  const handleOptionChange = (option: string) => {
    setSelectedOption(option); // Ustawia wybraną gminę w stanie
  };


  return (
    <>
    <button onClick={addUser} hidden>Dodaj rekord do bazy</button>
    <button onClick={getUsers} hidden>Wypisz w konsoli</button>

      <NavbarComponent onOptionChange={handleOptionChange} /> {/* Przekazuje funkcję obsługi zmiany wybranej opcji */}
      <MapComponent selectedOption={selectedOption} /> {/* Przekazuje wybraną opcję do MapComponent */}
      <CookieBanner/>
    </>
  );
}

export default App;
