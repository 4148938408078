import React, { useEffect, useState} from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import GeoJSON from 'ol/format/GeoJSON.js';



interface MapComponentProps {
  selectedOption: string;
}

const pointsLosos = [
{ id: 1, lon: 20.65042948855038, lat: 49.695802555347086},
{ id: 2, lon: 20.633801936815626, lat: 49.69731393098688},
{ id: 3, lon: 20.656894842628514, lat: 49.700800480122865},
{ id: 4, lon: 20.64484286926347, lat: 49.70851535429867},
{ id: 5, lon: 20.643576279248055, lat: 49.70982004771576},
{ id: 6, lon: 20.6340989646241, lat: 49.70562319465988},
{ id: 7, lon: 20.614858966233474, lat: 49.70451480414318},
{ id: 8, lon: 20.619763051433317, lat: 49.699310251285944},
{ id: 9, lon: 20.611462533861925, lat: 49.69456477579066},

{ id: 10, lon: 20.60561233545247, lat: 49.69036116146024},
{ id: 11, lon: 20.60543274876296, lat: 49.69004735069103},
{ id: 12, lon: 20.604981928620717, lat: 49.70233156153578},
{ id: 13, lon: 20.58354931854393, lat: 49.713340033724734},
{ id: 14, lon: 20.60217617292499, lat: 49.718204498414146},
{ id: 15, lon: 20.63010713663083, lat: 49.71376286795618},
{ id: 16, lon: 20.62032402501948, lat: 49.71479968191253},
{ id: 17, lon: 20.615638267263936, lat: 49.716504398901584},
{ id: 18, lon: 20.610146009153475, lat: 49.718268879689425},
{ id: 19, lon: 20.600729469472096, lat: 49.723176259709646},

{ id: 20, lon: 20.590759813542224, lat: 49.7345655776975},
{ id: 21, lon: 20.5870259985445, lat: 49.73018977790945},
{ id: 22, lon: 20.583145971593012, lat: 49.74174451770732},
{ id: 23, lon: 20.572362826577738, lat: 49.73359288525881},
{ id: 24, lon: 20.55778206797563, lat: 49.730348469331645},
{ id: 25, lon: 20.58069653385269, lat: 49.743065413559115},
{ id: 26, lon: 20.593304553470688, lat: 49.736108418183086},
{ id: 27, lon: 20.612260072990164, lat: 49.73188468510654},
{ id: 28, lon: 20.61727269403659, lat: 49.74223584265979},
{ id: 29, lon: 20.625876282545967, lat: 49.7409405589854},

{ id: 30, lon: 20.624852050591805, lat: 49.74177424861175},
{ id: 31, lon: 20.624720912416926, lat: 49.74203294589829},
{ id: 32, lon: 20.615377563953682, lat: 49.755086512675334},
{ id: 33, lon: 20.62458801338689, lat: 49.75276443363016},
{ id: 34, lon: 20.607959298516104, lat: 49.76408128523717},
{ id: 35, lon: 20.630903910347694, lat: 49.76554386045194},
{ id: 36, lon: 20.638961518567026, lat: 49.76953869888963},
{ id: 37, lon: 20.641040742850613, lat: 49.769976412585},
{ id: 38, lon: 20.647094869794905, lat: 49.77342355617274},
{ id: 39, lon: 20.65729249454713, lat: 49.77414241363844},

{ id: 40, lon: 20.66153101023675, lat: 49.780897703099846},
{ id: 41, lon: 20.661774343559788, lat: 49.787556513472225},
{ id: 42, lon: 20.64915590476543, lat: 49.786715278177596},
{ id: 43, lon: 20.6628582026001, lat: 49.73528604991488},
{ id: 44, lon: 20.642460813970075, lat: 49.72440935700802},
{ id: 45, lon: 20.641481348053144, lat: 49.724570031502644},
{ id: 46, lon: 20.640578596312313, lat: 49.72490239618445},
{ id: 47, lon: 20.637758886088136, lat: 49.73548077617516},
{ id: 48, lon: 20.637575744424062, lat: 49.736380925229106},
{ id: 49, lon: 20.63777105299272, lat: 49.73692665981591},

{ id: 50, lon: 20.67295393082685, lat: 49.72785677451792},
{ id: 51, lon: 20.67983813665205, lat: 49.72907902587278},
{ id: 52, lon: 20.662205958174496, lat: 49.73642893829424},
{ id: 53, lon: 20.66249993658711, lat: 49.73562869424109},
{ id: 54, lon: 20.63171837226881, lat: 49.740390854336},
{ id: 55, lon: 20.64090672016946, lat: 49.74831097331664},
{ id: 56, lon: 20.68702005135027, lat: 49.74324040284569},
{ id: 57, lon: 20.687973015255558, lat: 49.73313746568237},
{ id: 58, lon: 20.701008177164777, lat: 49.739230817739}
];

const pointsKrzesz = [
  { id: 1, lon: 19.62337931204127, lat: 50.12161597474173},
{ id: 2, lon: 19.613307040442237, lat: 50.11862325091133},
{ id: 3, lon: 19.616222608517567, lat: 50.11438548405268},
{ id: 4, lon: 19.63357544525668, lat: 50.11987634226357},
{ id: 5, lon: 19.621445481961253, lat: 50.11721895299421},
{ id: 6, lon: 19.61610632862914, lat: 50.11727103234208},
{ id: 7, lon: 19.629878089811548, lat: 50.126516105062905},
{ id: 8, lon: 19.631580657018738, lat: 50.1338655716083},
{ id: 9, lon: 19.631518730194173, lat: 50.13469537246983},
{ id: 10, lon: 19.632329173915306, lat: 50.135004264810945},
{ id: 11, lon: 19.631911987655585, lat: 50.13519599528906},
{ id: 12, lon: 19.6280099077657, lat: 50.13374458810798},
{ id: 13, lon: 19.62781947092523, lat: 50.133498729040205},
{ id: 14, lon: 19.636204304281172, lat: 50.133759901241866},
{ id: 15, lon: 19.633130783440738, lat: 50.13450386764698},
{ id: 16, lon: 19.635554314427157, lat: 50.13530282638768},
{ id: 17, lon: 19.636446962211743, lat: 50.135016160586666},
{ id: 18, lon: 19.637986676253906, lat: 50.13568102713876},
{ id: 19, lon: 19.637464187202173, lat: 50.13842317822552},
{ id: 20, lon: 19.633582150651804, lat: 50.136885134168885},
{ id: 21, lon: 19.633720954968627, lat: 50.13688900231679},
{ id: 22, lon: 19.634059280767374, lat: 50.136925904428956},
{ id: 23, lon: 19.633413366101962, lat: 50.136916704377526},
{ id: 24, lon: 19.638429734471917, lat: 50.13709549419807},
{ id: 25, lon: 19.642126667088895, lat: 50.13722962824318},
{ id: 26, lon: 19.6428768165066, lat: 50.137888651827005},
{ id: 27, lon: 19.638250986697734, lat: 50.138446647006035},
{ id: 28, lon: 19.65633835750782, lat: 50.13915603702469},
{ id: 29, lon: 19.654757622346814, lat: 50.13315810715589},
{ id: 30, lon: 19.65184290403366, lat: 50.131163772838434},
{ id: 31, lon: 19.636550807969197, lat: 50.13189576773015},
{ id: 32, lon: 19.62945126555254, lat: 50.136038409002964},
{ id: 33, lon: 19.629731296943536, lat: 50.136843118109034},
{ id: 34, lon: 19.63047634574467, lat: 50.13792308984659},
{ id: 35, lon: 19.63237135387462, lat: 50.141555245268016},
{ id: 36, lon: 19.62210939364639, lat: 50.13261794528653},
{ id: 37, lon: 19.60878380666264, lat: 50.13019738103333},
{ id: 38, lon: 19.60742257333678, lat: 50.13024405836247},
{ id: 39, lon: 19.620369979310865, lat: 50.13597209140328},
{ id: 40, lon: 19.623905319604855, lat: 50.14637290501789},
{ id: 41, lon: 19.63204157650815, lat: 50.143641979418746},
{ id: 42, lon: 19.634692742747223, lat: 50.14890743249237},
{ id: 43, lon: 19.630066911789232, lat: 50.15495299417112},
{ id: 44, lon: 19.629736983381083, lat: 50.15548502830528},
{ id: 45, lon: 19.630319193639654, lat: 50.161906100299625},
{ id: 46, lon: 19.643811464875128, lat: 50.149234698727646},
{ id: 47, lon: 19.646020363386857, lat: 50.13886603961842},
{ id: 48, lon: 19.643222328720054, lat: 50.146401051474385},
{ id: 49, lon: 19.6449759181214, lat: 50.149701841338896},
{ id: 50, lon: 19.645024651909424, lat: 50.14978552677917},
{ id: 51, lon: 19.643174604237775, lat: 50.140100002359134},
{ id: 52, lon: 19.6415539275854, lat: 50.14370222451718},
{ id: 53, lon: 19.64648086211981, lat: 50.12580104049002},
{ id: 54, lon: 19.650748822787826, lat: 50.12680996852199},
{ id: 55, lon: 19.65023957156144, lat: 50.12723329956501},
{ id: 56, lon: 19.65859055952876, lat: 50.12753691242495},
{ id: 57, lon: 19.658828256822765, lat: 50.123183760733696},
{ id: 58, lon: 19.65922410143956, lat: 50.12294418705994},
{ id: 59, lon: 19.665803944840324, lat: 50.12065722176149},

{ id: 60, lon: 19.66076155561924, lat: 50.118009320415794},
{ id: 61, lon: 19.677493394997292, lat: 50.12390056117365},
{ id: 62, lon: 19.67240694105193, lat: 50.12251819588489},
{ id: 63, lon: 19.672438300928096, lat: 50.125157475949266},
{ id: 64, lon: 19.655202277209778, lat: 50.129582102670504},
{ id: 65, lon: 19.65992641103486, lat: 50.1404568934413},
{ id: 66, lon: 19.66204210692593, lat: 50.14115700503844},
{ id: 67, lon: 19.662850556798688, lat: 50.14168357776343},
{ id: 68, lon: 19.664412373258823, lat: 50.142197709752935},
{ id: 69, lon: 19.665131993997356, lat: 50.14249598513657},
{ id: 70, lon: 19.666864850178452, lat: 50.1432168346272},
{ id: 71, lon: 19.67624248293476, lat: 50.14524390422298},
{ id: 72, lon: 19.66484876998654, lat: 50.14343275258053},
{ id: 73, lon: 19.678458310318568, lat: 50.14402985489336},
{ id: 74, lon: 19.678621962599593, lat: 50.14837229301782},
{ id: 75, lon: 19.68217952682387, lat: 50.145462060333344},
{ id: 76, lon: 19.680936481914785, lat: 50.14475135062042},
{ id: 77, lon: 19.67777409339645, lat: 50.142450655858475},
{ id: 78, lon: 19.682020980498084, lat: 50.14248265095464},
{ id: 79, lon: 19.693433659524153, lat: 50.14546192545309},
{ id: 80, lon: 19.692624997894153, lat: 50.14919128848966},
{ id: 81, lon: 19.6932997109529, lat: 50.15174845633238},
{ id: 82, lon: 19.69283767034258, lat: 50.1561773066124},
{ id: 83, lon: 19.695348708307154, lat: 50.17003023273373},
{ id: 84, lon: 19.69333540407815, lat: 50.173509397271985},
{ id: 85, lon: 19.660201191531726, lat: 50.09123562831976},
{ id: 86, lon: 19.655209951667008, lat: 50.08754964080253},
{ id: 87, lon: 19.65376933655249, lat: 50.08602371266922},
{ id: 88, lon: 19.634875794014683, lat: 50.08571856654111},
{ id: 89, lon: 19.615688731515586, lat: 50.07956311555003},
{ id: 90, lon: 19.61110179828063, lat: 50.079129214753735},
{ id: 91, lon: 19.60448009967353, lat: 50.08022515646226},
{ id: 92, lon: 19.59904884133259, lat: 50.083525623050626},
{ id: 93, lon: 19.608997322292304, lat: 50.08890037517395},
{ id: 94, lon: 19.614775446754063, lat: 50.09425523736914},
{ id: 95, lon: 19.641798651913255, lat: 50.07308121951955},
{ id: 96, lon: 19.641848776384847, lat: 50.07295943147513},
{ id: 97, lon: 19.64404001273618, lat: 50.06744741842828},
{ id: 98, lon: 19.643674085266415, lat: 50.06658053322966},
{ id: 99, lon: 19.6427573459521, lat: 50.13471757748047},
{ id: 100, lon: 19.598671725638596, lat: 50.1307029935227},
{ id: 101, lon: 19.592614647169746, lat: 50.131585085906416},
{ id: 102, lon: 19.58942974178287, lat: 50.13228725311195},
{ id: 103, lon: 19.586527559212154, lat: 50.132671664525795},
{ id: 104, lon: 19.5837112515477, lat: 50.13199386037419},
{ id: 105, lon: 19.58195628329255, lat: 50.13373487174491},
{ id: 106, lon: 19.578780689051793, lat: 50.13076001719086},
{ id: 107, lon: 19.576818785278082, lat: 50.134653248302044},
{ id: 108, lon: 19.576036113630956, lat: 50.13461934470048},
{ id: 109, lon: 19.57527394042002, lat: 50.134777669295644},
{ id: 110, lon: 19.563097424320897, lat: 50.13726783170553},
{ id: 111, lon: 19.564503689059677, lat: 50.13418876736028},
{ id: 112, lon: 19.56521973227471, lat: 50.13363299404081},
{ id: 113, lon: 19.574493521515272, lat: 50.12711983351394},
{ id: 114, lon: 19.578930845927463, lat: 50.123440193193154},
{ id: 115, lon: 19.58032448757147, lat: 50.120588698512606},
{ id: 116, lon: 19.5836531864984, lat: 50.128665334622774},
{ id: 117, lon: 19.565304816360094, lat: 50.106899832599616},
{ id: 118, lon: 19.574272700939904, lat: 50.10256169320517},
{ id: 119, lon: 19.562478263863078, lat: 50.10620893575219},
{ id: 120, lon: 19.575594744949626, lat: 50.09829835914327},
{ id: 121, lon: 19.58092710433442, lat: 50.0991733304405},
{ id: 122, lon: 19.609135665320604, lat: 50.113089209129754},
{ id: 123, lon: 19.573609539470954, lat: 50.13777696741898},
{ id: 124, lon: 19.56968359298772, lat: 50.142103214393536},
{ id: 125, lon: 19.56739412689422, lat: 50.145561266383076},
{ id: 126, lon: 19.568520009267182, lat: 50.14804015117117},
{ id: 127, lon: 19.567814098507352, lat: 50.150984888282856},
{ id: 128, lon: 19.565882275984855, lat: 50.154867475282465},
{ id: 129, lon: 19.562811794023645, lat: 50.16166182104799},
{ id: 130, lon: 19.5627816913598, lat: 50.17022552241674},
{ id: 131, lon: 19.556654977920534, lat: 50.18069752972012},
{ id: 132, lon: 19.550101070893703, lat: 50.184702614918166},
{ id: 133, lon: 19.549520154437406, lat: 50.18394365528343},
{ id: 134, lon: 19.568311456013447, lat: 50.19323400698141},
{ id: 135, lon: 19.56930763804244, lat: 50.18862229368367},
{ id: 136, lon: 19.5700701459241, lat: 50.18436349572391},
{ id: 137, lon: 19.586092726122533, lat: 50.17728178456278},
{ id: 138, lon: 19.587719137911453, lat: 50.17475489063281},
{ id: 139, lon: 19.58768770507871, lat: 50.174786471256866},
{ id: 140, lon: 19.588312195535025, lat: 50.17410843107919},
{ id: 141, lon: 19.589078572861837, lat: 50.17227545588043},
{ id: 142, lon: 19.58975671100239, lat: 50.17067562717911},
{ id: 143, lon: 19.590760633023805, lat: 50.16960963182977},
{ id: 144, lon: 19.593085466944814, lat: 50.16549108295298},
{ id: 145, lon: 19.60094050566597, lat: 50.16007266654148},
{ id: 146, lon: 19.603601304243657, lat: 50.15663280896994},
{ id: 147, lon: 19.613637193354023, lat: 50.153898135857446},
{ id: 148, lon: 19.587018408768106, lat: 50.17685816749244},
{ id: 149, lon: 19.59379775910201, lat: 50.17418315347376},
{ id: 150, lon: 19.59461294790176, lat: 50.173432914013624},
{ id: 151, lon: 19.61352739545631, lat: 50.174834516967785},
{ id: 152, lon: 19.61781467143505, lat: 50.172785248761265},
{ id: 153, lon: 19.62317315880309, lat: 50.168396196301956},
{ id: 154, lon: 19.64750746904067, lat: 50.177955174064266},
{ id: 155, lon: 19.65219565337798, lat: 50.17811070987343},
{ id: 156, lon: 19.65735935274762, lat: 50.177675861779505},
{ id: 157, lon: 19.657426441953604, lat: 50.17769450676091},
{ id: 158, lon: 19.66447525531383, lat: 50.179708301810976},
{ id: 159, lon: 19.664526012073242, lat: 50.17971580266473},
{ id: 160, lon: 19.6299204476199, lat: 50.126578163801945},
{ id: 161, lon: 19.631975819830128, lat: 50.13516302902103},
{ id: 162, lon: 19.629045073061125, lat: 50.132910074684226},
{ id: 163, lon: 19.636566798550753, lat: 50.13410743825043},
{ id: 164, lon: 19.630627757321793, lat: 50.138181914951865},
{ id: 165, lon: 19.640095855243906, lat: 50.14475263128079},
{ id: 166, lon: 19.6502587108694, lat: 50.12724636909236},
{ id: 167, lon: 19.65731692782565, lat: 50.12822919810432},
{ id: 168, lon: 19.671558953004592, lat: 50.12527334238964}
];

const pointsCzern = [
  { id: 1 , lon:19.657360964357746, lat: 49.99522199090945},
{ id: 2 , lon:19.675483088494573, lat: 49.98774621438113},
{ id: 3 , lon:19.676366471046858, lat: 49.9873288942453},
{ id: 4 , lon:19.679121940192694, lat: 49.98875798260468},
{ id: 5 , lon:19.685118622526463, lat: 49.990771304734864},
{ id: 6 , lon:19.70231800656161, lat: 49.988478385962},
{ id: 7 , lon:19.7129074428467, lat: 49.98853744938778},
{ id: 8 , lon:19.71975848635274, lat: 49.98939743195116},
{ id: 9 , lon:19.727276642492747, lat: 49.98910872659533},
{ id: 10 , lon:19.727543413620904, lat: 49.991390634466434},
{ id: 11 , lon:19.726870512371534, lat: 49.98877931053292},
{ id: 12 , lon:19.727905640687055, lat: 49.98632020910755},
{ id: 13 , lon:19.74499006723322, lat: 49.98167950848715},
{ id: 14 , lon:19.754698887003187, lat: 49.98469105220349},
{ id: 15 , lon:19.75902134599654, lat: 49.983796446793164},
/*{ id: 16 , lon:19.766685799648464, lat: 49.98156878028424},*/
{ id: 17 , lon:19.74324230389188, lat: 49.99582880671367},
{ id: 18 , lon:19.74909843876306, lat: 49.99780751948338},
{ id: 19 , lon:19.751209821203453, lat: 49.998410683946695},
{ id: 20 , lon:19.75329834871718, lat: 49.9993497213075},
{ id: 21 , lon:19.735488577424494, lat: 49.998840286895025},
{ id: 22 , lon:19.73647738506674, lat: 49.99873674409003},
{ id: 23 , lon:19.734134004509148, lat: 50.001714859520604},
{ id: 24 , lon:19.743427152187742, lat: 49.99887798615803},
{ id: 25 , lon:19.700597838880334, lat: 49.99879333302374},
{ id: 26 , lon:19.71282205093103, lat: 50.0041612839109},
{ id: 27 , lon:19.710932765101894, lat: 50.00431653358012},
{ id: 28 , lon:19.708085273370106, lat: 50.00434737133068},
{ id: 29 , lon:19.702588197474576, lat: 50.00411965029112},
{ id: 30 , lon:19.702306882770515, lat: 50.00419113136185},
{ id: 31 , lon:19.69680923435675, lat: 49.99991620552019},
{ id: 32 , lon:19.714128631232498, lat: 49.99989668043882},
{ id: 33 , lon:19.697805473126927, lat: 50.027395038417},
{ id: 34 , lon:19.69842268047198, lat: 50.02769113713921},
{ id: 35 , lon:19.70526591515854, lat: 50.031294230097274},
{ id: 36 , lon:19.711382770231435, lat: 50.03395691992472},
{ id: 37 , lon:19.689885732757503, lat: 50.02576924648674},
{ id: 38 , lon:19.653363504499367, lat: 50.01792311292641},
{ id: 39 , lon:19.647063833217715, lat: 50.01490349083403},
{ id: 40 , lon:19.654687383592275, lat: 50.011836740145434},
{ id: 41 , lon:19.657597028488617, lat: 50.01157753555202},
{ id: 42 , lon:19.657632115209758, lat: 50.01174254474639},
{ id: 43 , lon:19.66062213591804, lat: 50.005730253347544},
{ id: 44 , lon:19.66768172821964, lat: 49.999895476886195},
{ id: 45 , lon:19.634814391707785, lat: 50.03280649196013},
{ id: 46 , lon:19.644313442863393, lat: 50.04121840960728},
{ id: 47 , lon:19.631683411612148, lat: 50.03413449016612},
{ id: 48 , lon:19.637824602651374, lat: 50.0441008728169},
{ id: 49 , lon:19.646401184551756, lat: 50.04821449720527},
{ id: 50 , lon:19.64681898202359, lat: 50.049338423075696},
{ id: 51 , lon:19.646863747197717, lat: 50.051169728796246},
{ id: 52 , lon:19.654833880520002, lat: 50.058851044225385},
{ id: 53 , lon:19.617139532431732, lat: 50.05492204638178},
{ id: 54 , lon:19.644608404788645, lat: 50.03442225558225},
{ id: 55 , lon:19.684230818513775, lat: 50.03848922098852},
{ id: 56 , lon:19.681936882880997, lat: 50.04049166220518},
{ id: 57 , lon:19.679868666210343, lat: 50.04228874572194},
{ id: 58 , lon:19.62677175211354, lat: 50.02480409710664},
{ id: 59 , lon:19.624607555679592, lat: 50.03045416441422},

/*{ id: 60 , lon:19.623180690954953, lat: 50.03189456246771},*/
{ id: 61 , lon:19.586744389807116, lat: 50.01500770960974},
{ id: 62 , lon:19.583552802033427, lat: 50.00852554440398},
{ id: 63 , lon:19.585843605686982, lat: 50.01648915089427},
{ id: 64 , lon:19.588164620216055, lat: 50.004936560099765},
{ id: 65 , lon:19.60348566238224, lat: 49.99989755763703},
{ id: 66 , lon:19.61267681798716, lat: 50.001132660440696},
{ id: 67 , lon:19.613008913451406, lat: 50.00121189771955},
{ id: 68 , lon:19.604693553236785, lat: 49.99701217150526},
{ id: 69 , lon:19.617091612349647, lat: 49.997781449336834},
{ id: 70 , lon:19.6392929482673, lat: 49.99499939830803}
];

const pointsJerz = [
{ id: 1, lon: 19.685206450479747, lat: 50.23771696155016},
{ id: 2, lon: 19.68857053614077, lat: 50.23764684180029},
{ id: 3, lon: 19.701040129031956, lat: 50.23564951201585},
{ id: 4, lon: 19.71121474222021, lat: 50.23311904887965},
{ id: 5, lon: 19.686239422029047, lat: 50.23800364398192},
{ id: 6, lon: 19.687431571781207, lat: 50.23772160576565},
{ id: 7, lon: 19.699659202652334, lat: 50.23599887987606},
{ id: 8, lon: 19.702640725081288, lat: 50.23512117791837},
{ id: 9, lon: 19.711959786552853, lat: 50.23274334473685},

{ id: 10, lon: 19.712902387157317, lat: 50.23249951222857},
{ id: 11, lon: 19.71742274736475, lat: 50.23102691721418},
{ id: 12, lon: 19.720042969902842, lat: 50.230611769891794},
{ id: 13, lon: 19.721489205585755, lat: 50.230131385360295},
{ id: 14, lon: 19.735389385761323, lat: 50.22662532638753},
{ id: 15, lon: 19.736075425228005, lat: 50.22863893932568},
{ id: 16, lon: 19.73573573704203, lat: 50.22466255019939},
{ id: 17, lon: 19.737417419456712, lat: 50.224141725703824},
{ id: 18, lon: 19.74099255569953, lat: 50.222107192968444},
{ id: 19, lon: 19.748414816797144, lat: 50.22185361330051},

{ id: 20, lon: 19.729048569796678, lat: 50.21469757566541},
{ id: 21, lon: 19.748619488577436, lat: 50.21331726394329},
{ id: 22, lon: 19.74989420085816, lat: 50.221131286619965},
{ id: 23, lon: 19.75680149192187, lat: 50.21827486858692},
{ id: 24, lon: 19.756356249404547, lat: 50.216943693445636},
{ id: 25, lon: 19.74829703954804, lat: 50.21269042422354},
{ id: 26, lon: 19.74807677119611, lat: 50.21257129196171},
{ id: 27, lon: 19.746403847228525, lat: 50.211746757155325},
{ id: 28, lon: 19.746097880236306, lat: 50.2118946854886},
{ id: 29, lon: 19.741364682952636, lat: 50.20695203818664},

{ id: 30, lon: 19.734055808311915, lat: 50.20215825881376},
{ id: 31, lon: 19.7289505911058, lat: 50.19753276509495},
{ id: 32, lon: 19.7578615466885, lat: 50.217628964796006},
{ id: 33, lon: 19.744959430856692, lat: 50.19435251223224},
{ id: 34, lon: 19.745455995651668, lat: 50.1895570798114},
{ id: 35, lon: 19.744125867465772, lat: 50.1884557335595},
{ id: 36, lon: 19.73982167210653, lat: 50.18665251439055},
{ id: 37, lon: 19.73719952512036, lat: 50.18985052553165},
{ id: 38, lon: 19.718516620393608, lat: 50.187662889063176},
{ id: 39, lon: 19.71595432806905, lat: 50.18176947479171},

{ id: 40, lon: 19.715505310669357, lat: 50.17780629980027},
{ id: 41, lon: 19.714878809774454, lat: 50.174272867429856},
{ id: 42, lon: 19.71647032426393, lat: 50.17289309612264},
{ id: 43, lon: 19.71705591249157, lat: 50.17125198303324},
{ id: 44, lon: 19.73841271117812, lat: 50.18666125017305},
{ id: 45, lon: 19.73497337375987, lat: 50.18654040509597},
{ id: 46, lon: 19.75971056383124, lat: 50.2020134321625},
{ id: 47, lon: 19.77473826105884, lat: 50.19992415432544},
{ id: 48, lon: 19.78238147574801, lat: 50.19592264736622},
{ id: 49, lon: 19.787835520843043, lat: 50.1984439465172},

{ id: 50, lon: 19.772588115839437, lat: 50.20924518011061},
{ id: 51, lon: 19.773184757877587, lat: 50.20896733611566},
{ id: 52, lon: 19.774132691304864, lat: 50.20840472195773},
{ id: 53, lon: 19.778442882751243, lat: 50.20633670931112},
{ id: 54, lon: 19.784186153525805, lat: 50.20224914606685},
{ id: 55, lon: 19.784802013306795, lat: 50.201766086041374},
{ id: 56, lon: 19.665772939324214, lat: 50.225548747909755},
{ id: 57, lon: 19.662286693880958, lat: 50.214536124792986},
{ id: 58, lon: 19.676405059016716, lat: 50.20168896649163},
{ id: 59, lon: 19.676324677245784, lat: 50.201445502228914},

{ id: 60, lon: 19.675911798212343, lat: 50.20106915742264},
{ id: 61, lon: 19.676955123889115, lat: 50.19552624872393},
{ id: 62, lon: 19.676550979825684, lat: 50.18924014925206},
{ id: 63, lon: 19.675684126243688, lat: 50.18603861613465},
{ id: 64, lon: 19.684228838296782, lat: 50.20371259465716},
{ id: 65, lon: 19.688105644108507, lat: 50.20693317306789},
{ id: 66, lon: 19.687760010827656, lat: 50.20838290984171},
{ id: 67, lon: 19.68782919932646, lat: 50.213191404123144},
{ id: 68, lon: 19.688369528733, lat: 50.21652572085702},
{ id: 69, lon: 19.692137229767333, lat: 50.21978999669329},

{ id: 70, lon: 19.736833801095433, lat: 50.2314747769812},
{ id: 71, lon: 19.763791507574386, lat: 50.226003342290184},
{ id: 72, lon: 19.768013754760297, lat: 50.227589625813515},
{ id: 73, lon: 19.77068809389186, lat: 50.22307397285157},
{ id: 74, lon: 19.773602489419652, lat: 50.221102121344124},
{ id: 75, lon: 19.77275460206337, lat: 50.22326762111546},
{ id: 76, lon: 19.775132977080833, lat: 50.23016138526179},
{ id: 77, lon: 19.77542997233732, lat: 50.23022253381986},
{ id: 78, lon: 19.776258548580053, lat: 50.23032010464694},
{ id: 79, lon: 19.788540523722638, lat: 50.23002489547842}
];

const pointsZabierzow = [
  { id: 1, lon: 19.708085371348847, lat: 50.14450861308024},
{ id: 2, lon: 19.708820906299763, lat: 50.14774535291132},
{ id: 3, lon: 19.709330880990663, lat: 50.14833417845636},
{ id: 4, lon: 19.709288305783282, lat: 50.148244270837225}, // przystanek-wiata
{ id: 5, lon: 19.71154788125489, lat: 50.15023629863978},
{ id: 6, lon: 19.712562832607166, lat: 50.14836165208233},
{ id: 7, lon: 19.707352724473754, lat: 50.142168517826725},
{ id: 8, lon: 19.707273538539365, lat: 50.14110759883586}, // przystanek-wiata
{ id: 9, lon: 19.713517499511873, lat: 50.12512897869885},

{ id: 10, lon: 19.69343720486437, lat: 50.14437183677324}, // przystanek-wiata
{ id: 11, lon: 19.69337663605587, lat: 50.14410756983455},
{ id: 12, lon: 19.693506066849885, lat: 50.14159396587749}, // przystanek-wiata
{ id: 13, lon: 19.692837927723865, lat: 50.135180460833226}, // przystanek-wiata
{ id: 14, lon: 19.69304695070188, lat: 50.13512704613011}, // przystanek-wiata
{ id: 15, lon: 19.69286294073166, lat: 50.13511006182175},
{ id: 16, lon: 19.69201299369622, lat: 50.12910083989102},
{ id: 17, lon: 19.69528348397463, lat: 50.126225374221846},
{ id: 18, lon: 19.696719505145605, lat: 50.125619675100126}, // przystanek-wiata
{ id: 19, lon: 19.696753005508413, lat: 50.125707731950136}, // przystanek-wiata

{ id: 20, lon: 19.702193863716285, lat: 50.12477081365578},
{ id: 21, lon: 19.70216381149066, lat: 50.12465578644344}, // przystanek-wiata
{ id: 22, lon: 19.706507995854206, lat: 50.12316807557048}, // przystanek-wiata
{ id: 23, lon: 19.70732584998231, lat: 50.12340695112943}, // przystanek-wiata
{ id: 24, lon: 19.70942053080432, lat: 50.122329555888065},
{ id: 25, lon: 19.712499227284564, lat: 50.12122497973762},
{ id: 26, lon: 19.71350788313292, lat: 50.11979888439298}, // przystanek-wiata
{ id: 27, lon: 19.713387265111624, lat: 50.119812718190474}, // przystanek-wiata
{ id: 28, lon: 19.712172983611442, lat: 50.12179466959078},
{ id: 29, lon: 19.712121828192746, lat: 50.122804683669976},

{ id: 30, lon: 19.713525100149962, lat: 50.12015209497475},
{ id: 31, lon: 19.71295045880576, lat: 50.1172255482587},
{ id: 32, lon: 19.71154149390656, lat: 50.11446076041634}, // przystanek-wiata
{ id: 33, lon: 19.720953310107628, lat: 50.12716129894131}, // przystanek-wiata
{ id: 34, lon: 19.720948990897657, lat: 50.12731224000252}, // przystanek-wiata
{ id: 35, lon: 19.72755450481996, lat: 50.122517199793776}, 
{ id: 36, lon: 19.732468195987767, lat: 50.12371195021285},
{ id: 37, lon: 19.733528567644626, lat: 50.112870952391994},
{ id: 38, lon: 19.734304540109722, lat: 50.12401378887835},
{ id: 39, lon: 19.734317962413847, lat: 50.1245178275453}, // przystanek-wiata

{ id: 40, lon: 19.739707342661116, lat: 50.13534642441544}, // przystanek-wiata
{ id: 41, lon: 19.739701161903785, lat: 50.13561437348485}, // przystanek-wiata
{ id: 42, lon: 19.73882346549077, lat: 50.1377082275748}, // przystanek-wiata
{ id: 43, lon: 19.73863582473272, lat: 50.137877688528036}, // przystanek-wiata
{ id: 44, lon: 19.738559029513322, lat: 50.138038695094544},
{ id: 45, lon: 19.740488978327516, lat: 50.15146903613847},
{ id: 46, lon: 19.747792545098097, lat: 50.13822884348249}, // przystanek-wiata
{ id: 47, lon: 19.74810332793788, lat: 50.138198985003726}, // przystanek-wiata
{ id: 48, lon: 19.75305042106527, lat: 50.14803972569364}, // przystanek-wiata
{ id: 49, lon: 19.753007728511783, lat: 50.148134304905945}, // przystanek-wiata

{ id: 50, lon: 19.758156192809206, lat: 50.14868349347853},
{ id: 51, lon: 19.761314129892757, lat: 50.147990914245455},
{ id: 52, lon: 19.762910243255536, lat: 50.14650903191984}, // przystanek-wiata
{ id: 53, lon: 19.767067664773126, lat: 50.141415858683644},
{ id: 54, lon: 19.76176789410205, lat: 50.15007867857421},
{ id: 55, lon: 19.762176857694648, lat: 50.13649144263011},
{ id: 56, lon: 19.759188079145932, lat: 50.135369275238936},
{ id: 57, lon: 19.75851302734382, lat: 50.13360941653779},
{ id: 58, lon: 19.76384057498662, lat: 50.13763873086104}, // przystanek-wiata
{ id: 59, lon: 19.763892072980987, lat: 50.13779166493748}, // przystanek-wiata

{ id: 60, lon: 19.76833934271779, lat: 50.13850035237221},
{ id: 61, lon: 19.768200672029064, lat: 50.13926724617141}, // przystanek-wiata
{ id: 62, lon: 19.76974436639983, lat: 50.137862934219854}, // przystanek-wiata
{ id: 63, lon: 19.77018139349872, lat: 50.137522655337065},
{ id: 64, lon: 19.777652191651725, lat: 50.133963318608785}, // przystanek-wiata
{ id: 65, lon: 19.778998813599834, lat: 50.13364766496432},
{ id: 66, lon: 19.764714538576012, lat: 50.15529673595637},
{ id: 67, lon: 19.776181420811284, lat: 50.155424321493605},
{ id: 68, lon: 19.77389806799129, lat: 50.152409435853315}, // przystanek-wiata
{ id: 69, lon: 19.7784095270094, lat: 50.14989009962872},

{ id: 70, lon: 19.77916194382631, lat: 50.149366296128974}, // przystanek-wiata
{ id: 71, lon: 19.772287883693306, lat: 50.15135825270979},
{ id: 72, lon: 19.774103140914356, lat: 50.1509099755471},
{ id: 73, lon: 19.774889316378246, lat: 50.15050667966484},
{ id: 74, lon: 19.79058635439118, lat: 50.147331814063975},
{ id: 75, lon: 19.791658464493796, lat: 50.1471440780521}, // przystanek-wiata
{ id: 76, lon: 19.791381355804265, lat: 50.147269257395216},
{ id: 77, lon: 19.791331305089066, lat: 50.147337177323756}, // przystanek-wiata
{ id: 78, lon: 19.79279742442665, lat: 50.147371439725745},
{ id: 79, lon: 19.792728515264148, lat: 50.14650807014758}, // przystanek-wiata

{ id: 80, lon: 19.796620395141897, lat: 50.142400654036656}, // przystanek-wiata
{ id: 81, lon: 19.796783598710654, lat: 50.142057964564806},
{ id: 82, lon: 19.799077902887685, lat: 50.135162082832274},
{ id: 83, lon: 19.79185897384145, lat: 50.131901335048326},
{ id: 84, lon: 19.79943703585851, lat: 50.15393014810704}, // przystanek-wiata
{ id: 85, lon: 19.81566660732134, lat: 50.13946930556324},
{ id: 86, lon: 19.796308377234443, lat: 50.15970583515164},
{ id: 87, lon: 19.796166881927345, lat: 50.1595503250611}, // przystanek-wiata
{ id: 88, lon: 19.800232830944044, lat: 50.16131554042211},
{ id: 89, lon: 19.792785439861994, lat: 50.158954571911636},

{ id: 90, lon: 19.811584886349035, lat: 50.16158893469819},
{ id: 91, lon: 19.811572145858705, lat: 50.16147423876339},
{ id: 92, lon: 19.818603673410188, lat: 50.13581617304897},
{ id: 93, lon: 19.818307384639706, lat: 50.13724528789988},
{ id: 94, lon: 19.819861412078506, lat: 50.13565758592869},
{ id: 95, lon: 19.81871605797764, lat: 50.137527219881136},
{ id: 96, lon: 19.819543513838198, lat: 50.13830312833903},
{ id: 97, lon: 19.820386620629687, lat: 50.13579053091718}, // przystanek-wiata
{ id: 98, lon: 19.822070954439635, lat: 50.14021712326055},
{ id: 99, lon: 19.821577167477955, lat: 50.132226708252894}, // przystanek-wiata

{ id: 100, lon: 19.82187572360295, lat: 50.129299998038434}, // przystanek-wiata
{ id: 101, lon: 19.821757699881154, lat: 50.128186268511115},
{ id: 102, lon: 19.821642150169687, lat: 50.124359875459255}, // przystanek-wiata
{ id: 103, lon: 19.81443389762258, lat: 50.12501260681987},
{ id: 104, lon: 19.815250890312505, lat: 50.12482030480382}, // przystanek-wiata
{ id: 105, lon: 19.82164763022821, lat: 50.12218061550316},
{ id: 106, lon: 19.82730464178381, lat: 50.11775859242671},
{ id: 107, lon: 19.829055253383377, lat: 50.11651009233784}, // przystanek-wiata
{ id: 108, lon: 19.803535999300056, lat: 50.12704254531843}, // przystanek-wiata
{ id: 109, lon: 19.801668374494263, lat: 50.11973155684637}, // przystanek-wiata

{ id: 110, lon: 19.801531531488443, lat: 50.11909499315783},
{ id: 111, lon: 19.798523961874636, lat: 50.118715373637464},
{ id: 112, lon: 19.803414207446895, lat: 50.11283266449305},
{ id: 113, lon: 19.800474937595062, lat: 50.11094056491688},
{ id: 114, lon: 19.799042802391643, lat: 50.11338545038455},
{ id: 115, lon: 19.801005196011, lat: 50.11291340190721},
{ id: 116, lon: 19.79946277590411, lat: 50.11598604461538},
{ id: 117, lon: 19.799870023712963, lat: 50.116446394749914}, // przystanek-wiata
{ id: 118, lon: 19.80002644737249, lat: 50.11705511127888}, // przystanek-wiata
{ id: 119, lon: 19.80338658415621, lat: 50.11039264049991},

{ id: 120, lon: 19.804578989421636, lat: 50.11274713660399},
{ id: 121, lon: 19.80952310518783, lat: 50.112357869626},
{ id: 122, lon: 19.810157149807228, lat: 50.11220555317971}, // przystanek-wiata
{ id: 123, lon: 19.81550005434354, lat: 50.10976834330183},
{ id: 124, lon: 19.824106016672488, lat: 50.106753201638945}, // przystanek-wiata
{ id: 125, lon: 19.825659191999403, lat: 50.10913093627717}, // przystanek-wiata
{ id: 126, lon: 19.826475885192878, lat: 50.10910765981688},
{ id: 127, lon: 19.835871835008376, lat: 50.10551518420761}, // przystanek-wiata
{ id: 128, lon: 19.83609248158894, lat: 50.102465149424454},
{ id: 129, lon: 19.83654867109566, lat: 50.099268074},

{ id: 130, lon: 19.8368016363345, lat: 50.099125488819666}, // przystanek-wiata
{ id: 131, lon: 19.838338740315038, lat: 50.09881414749555},
{ id: 132, lon: 19.838547856677376, lat: 50.09874288876832}, // przystanek-wiata
{ id: 133, lon: 19.83990075850249, lat: 50.096863007719946},
{ id: 134, lon: 19.841929449087, lat: 50.096332453983},
{ id: 135, lon: 19.845502620646933, lat: 50.09682622390966}, // przystanek-wiata
{ id: 136, lon: 19.84605044051703, lat: 50.09696407259682}, // przystanek-wiata
{ id: 137, lon: 19.85646747207015, lat: 50.099752876998316}, // przystanek-wiata
{ id: 138, lon: 19.860568261800175, lat: 50.101137207127216}, // przystanek-wiata
{ id: 139, lon: 19.862054734383744, lat: 50.102307688253}, // przystanek-wiata

{ id: 140, lon: 19.836880324498143, lat: 50.09033118972294},
{ id: 141, lon: 19.827243790100503, lat: 50.09180652084566},
{ id: 142, lon: 19.818823196923454, lat: 50.09140480747482},
{ id: 143, lon: 19.808235834977612, lat: 50.09374675615771},
{ id: 144, lon: 19.804630161619226, lat: 50.09328557269737}, // przystanek-wiata
{ id: 145, lon: 19.803795875170916, lat: 50.09353015567538},
{ id: 146, lon: 19.809143601812746, lat: 50.08651247683073}, // przystanek-wiata
{ id: 147, lon: 19.796128092107566, lat: 50.089437129703335},
{ id: 148, lon: 19.792188349684167, lat: 50.086367201086574},
{ id: 149, lon: 19.791520990538043, lat: 50.08653371565938},

{ id: 150, lon: 19.791228875250525, lat: 50.08672839086252}, // przystanek-wiata
{ id: 151, lon: 19.789405630049455, lat: 50.088399020281656},
{ id: 152, lon: 19.786629232543806, lat: 50.08986998630467}, // przystanek-wiata
{ id: 153, lon: 19.783044915938927, lat: 50.090467391376926}, // przystanek-wiata
{ id: 154, lon: 19.77986714993328, lat: 50.09124089070839},
{ id: 155, lon: 19.79031701318481, lat: 50.083713099892286}, // przystanek-wiata
{ id: 156, lon: 19.788940387600846, lat: 50.08388808455672}, // przystanek-wiata
{ id: 157, lon: 19.769275318164045, lat: 50.081679627342744}, // przystanek-wiata
{ id: 158, lon: 19.769174244790143, lat: 50.0815901228451}, // przystanek-wiata
{ id: 159, lon: 19.76853412420522, lat: 50.08180102629379},

{ id: 160, lon: 19.76407334325402, lat: 50.08117332456179},
{ id: 161, lon: 19.758189867808017, lat: 50.085408410726444},
{ id: 162, lon: 19.757979013168057, lat: 50.08563923143842}, // przystanek-wiata
{ id: 163, lon: 19.75195220127641, lat: 50.090696154141476},
{ id: 164, lon: 19.748642045288346, lat: 50.1016258148357},
{ id: 165, lon: 19.74891437430419, lat: 50.10496218688946},
{ id: 166, lon: 19.74196666524619, lat: 50.10427946450997},
{ id: 167, lon: 19.769376236685513, lat: 50.09528714659258}, // przystanek-wiata
{ id: 168, lon: 19.769298121572604, lat: 50.09499315012441},
{ id: 169, lon: 19.77448036513528, lat: 50.09343267590609}, // przystanek-wiata

{ id: 170, lon: 19.774287449769638, lat: 50.093539797913756},
{ id: 171, lon: 19.744976589217867, lat: 50.114439596122345},
{ id: 172, lon: 19.74743027465415, lat: 50.11482826596647},
{ id: 173, lon: 19.74835607048144, lat: 50.1148488473947}, // przystanek-wiata
{ id: 174, lon: 19.751849362800286, lat: 50.115276581297316},
{ id: 175, lon: 19.763745091901747, lat: 50.11623496797938},// przystanek-wiata
{ id: 176, lon: 19.77096166124973, lat: 50.11739085264457},
{ id: 177, lon: 19.773169672823073, lat: 50.11741217621269},
{ id: 178, lon: 19.77477934630295, lat: 50.11723982652381}, // przystanek-wiata
{ id: 179, lon: 19.77660789556372, lat: 50.11858056753316},

{ id: 180, lon: 19.778068818425954, lat: 50.11699271514426}, // przystanek-wiata
{ id: 181, lon: 19.784410476236236, lat: 50.11693948314243}, // przystanek-wiata
{ id: 182, lon: 19.784808171886116, lat: 50.11701167105078},
{ id: 183, lon: 19.785869408299785, lat: 50.11912373891317},
{ id: 184, lon: 19.792529778769236, lat: 50.115561119580605}, // przystanek-wiata
{ id: 185, lon: 19.801182986059715, lat: 50.071830218319704}, // przystanek-wiata
{ id: 186, lon: 19.8003416940997, lat: 50.07172986467789}, // przystanek-wiata
{ id: 187, lon: 19.79591779522001, lat: 50.064426182609225}, // przystanek-wiata
{ id: 188, lon: 19.7831283439072, lat: 50.068625833148815},
{ id: 189, lon: 19.735310254269816, lat: 50.11294581232237},

{ id: 190, lon: 19.713271708146998, lat: 50.11346460326364}, // przystanek-wiata
{ id: 191, lon: 19.711024279725155, lat: 50.11377540521674},
{ id: 192, lon: 19.733478937204858, lat: 50.112908590228336},
{ id: 193, lon: 19.709347383168602, lat: 50.11388927974873}, // przystanek-wiata
{ id: 194, lon: 19.702468820373873, lat: 50.114209056816456},
{ id: 195, lon: 19.70101425256386, lat: 50.11438039524739}, // przystanek-wiata
{ id: 196, lon: 19.698687827337245, lat: 50.11461759821531},
{ id: 197, lon: 19.6975157024376, lat: 50.11391046931443}, // przystanek-wiata
{ id: 198, lon: 19.69526170563231, lat: 50.11300811528404},
{ id: 199, lon: 19.692358811999465, lat: 50.11202844537882},

{ id: 200, lon: 19.700991096017212, lat: 50.11238145588609},
{ id: 201, lon: 19.699206508515474, lat: 50.110928107005606},
{ id: 202, lon: 19.700497771092206, lat: 50.10797027074484},
{ id: 203, lon: 19.702503307900514, lat: 50.10829117482483},
{ id: 204, lon: 19.70665901961096, lat: 50.10809753550785},
{ id: 205, lon: 19.70655131218653, lat: 50.10758309020503},
{ id: 206, lon: 19.705026719356997, lat: 50.107732387385376},
{ id: 207, lon: 19.70486166998594, lat: 50.10775430975945},
{ id: 208, lon: 19.7094403111564, lat: 50.10655522714883},
{ id: 209, lon: 19.715199433061017, lat: 50.103086557224756},

{ id: 210, lon: 19.718369001726828, lat: 50.09547375682729},
{ id: 211, lon: 19.71013987160441, lat: 50.093221958687195},
{ id: 212, lon: 19.703816361764265, lat: 50.09047784415791},
{ id: 213, lon: 19.702935132511367, lat: 50.08994136825239}, // przystanek-wiata
{ id: 214, lon: 19.734915443216853, lat: 50.09058617974578},
{ id: 215, lon: 19.691025280665208, lat: 50.117403009191044},
{ id: 216, lon: 19.692540142531872, lat: 50.11892404746594}, // przyzaastanek-wiata
{ id: 217, lon: 19.6918006154978, lat: 50.119643417103305}, // przystanek-wiata
];


const pointsOlkusz = [

  { id: 1, lon: 19.534841949786266, lat: 50.287675694170176}, // przystanek
  { id: 2, lon: 19.54392732185218, lat: 50.28974512973608},
  { id: 3, lon: 19.54411605603082, lat: 50.293539150575704},
  { id: 4, lon: 19.547641057030706, lat: 50.30247930775916}, // przystanek
  { id: 5, lon: 19.54773881312302, lat: 50.3024778385781},
  { id: 6, lon: 19.547830891443073, lat: 50.30761921917571}, // przystanek
  { id: 7, lon: 19.54827757985732, lat: 50.30789460788307},
  { id: 8, lon: 19.553127359809963, lat: 50.31222725139702},
  { id: 9, lon: 19.55684614972143, lat: 50.286178182840445},
  
  { id: 10, lon: 19.551918895711566, lat: 50.283748265350994}, // przystanek
  { id: 11, lon: 19.556910101420005, lat: 50.2839498973134}, // przystanek
  { id: 12, lon: 19.564258743224325, lat: 50.28341117171377},
  { id: 13, lon: 19.563898331556224, lat: 50.28128612738139}, // przystanek
  { id: 14, lon: 19.56570813877933, lat: 50.28132361405526}, // przystanek
  { id: 15, lon: 19.57262353457609, lat: 50.28004587201051},
  { id: 16, lon: 19.569194419072982, lat: 50.284194084121594}, // przystanek
  { id: 17, lon: 19.571340612012886, lat: 50.28572655490901},
  { id: 18, lon: 19.579998608636625, lat: 50.282168589973956},
  { id: 19, lon: 19.582459621172294, lat: 50.281661544502725},
  
  { id: 20, lon: 19.58266225869178, lat: 50.28438357620212},
  { id: 21, lon: 19.588432218505353, lat: 50.28432163162059},
  { id: 22, lon: 19.579825366236797, lat: 50.27788590575682},
  { id: 23, lon: 19.585773508519207, lat: 50.27966275506991},
  { id: 24, lon: 19.58811537215688, lat: 50.278561346260176}, // przystanek
  { id: 25, lon: 19.588057463247402, lat: 50.278512649815355}, // przystanek
  { id: 26, lon: 19.58826865137311, lat: 50.278492771819984},
  { id: 27, lon: 19.58822094551564, lat: 50.278173939928344},
  { id: 28, lon: 19.58262216142087, lat: 50.27809287893467},
  { id: 29, lon: 19.581376816823344, lat: 50.27677398890243}, // przystanek
  
  { id: 30, lon: 19.58961877273649, lat: 50.275794320909505},
  { id: 31, lon: 19.585695469742593, lat: 50.27462740065779}, // przystanek
  { id: 32, lon: 19.579763790502945, lat: 50.2774672436832}, // przystanek
  { id: 33, lon: 19.5772785232563, lat: 50.29010727327503}, // przystanek
  { id: 34, lon: 19.577865019348906, lat: 50.29040868697183}, // przystanek
  { id: 35, lon: 19.573942243072743, lat: 50.29565752458425}, // przystanek
  { id: 36, lon: 19.53867258267641, lat: 50.28759194464621}, // przystanek
  { id: 37, lon: 19.54673801763439, lat: 50.2835876902018},
  { id: 38, lon: 19.55067711685163, lat: 50.280666960903645},
  { id: 39, lon: 19.560462603797873, lat: 50.27865111716331},
  
  { id: 40, lon: 19.561414126447705, lat: 50.28155599870513},
  { id: 41, lon: 19.559503793071148, lat: 50.27769461078021}, // przystanek
  { id: 42, lon: 19.563983756527062, lat: 50.280322556035614},
  { id: 43, lon: 19.566293772359824, lat: 50.28064915642858}, // przystanek
  { id: 44, lon: 19.566702926155486, lat: 50.27840615341259},
  { id: 45, lon: 19.564592438692287, lat: 50.27742607029927},
  { id: 46, lon: 19.566328899752094, lat: 50.27682429248843},
  { id: 47, lon: 19.568920352390908, lat: 50.27632097328389},
  { id: 48, lon: 19.573543513694066, lat: 50.275021145461885},
  { id: 49, lon: 19.57419686005096, lat: 50.27498930868335}, // przystanek
  
  { id: 50, lon: 19.575229260247315, lat: 50.27496385173701}, // przystanek
  { id: 51, lon: 19.574052629443848, lat: 50.27500432769483},
  { id: 52, lon: 19.57087239153439, lat: 50.27586714386572},
  { id: 53, lon: 19.581053739973296, lat: 50.27421246296496}, // przystanek
  { id: 54, lon: 19.58226978952289, lat: 50.27409255264105}, // przystanek
  { id: 55, lon: 19.555074026963588, lat: 50.27225240291656},
  { id: 56, lon: 19.54857820992725, lat: 50.268023024058195},
  { id: 57, lon: 19.547953367672786, lat: 50.265310206503194},
  { id: 58, lon: 19.561710279534836, lat: 50.27415979116477},
  { id: 59, lon: 19.560218549581688, lat: 50.264135589995604}, // przystanek
  
  { id: 60, lon: 19.560484407568126, lat: 50.2631842550129}, // przystanek
  { id: 61, lon: 19.568721071647158, lat: 50.27239077925662},
  { id: 62, lon: 19.567541423005185, lat: 50.27385174069721},
  { id: 63, lon: 19.56847733878696, lat: 50.26966238139083}, // przystanek
  { id: 64, lon: 19.56951152791817, lat: 50.269814845179894}, // przystanek
  { id: 65, lon: 19.576662549499385, lat: 50.26976263565339}, // przystanek
  { id: 66, lon: 19.577872429526966, lat: 50.2696946186167}, // przystanek
  { id: 67, lon: 19.56669162918283, lat: 50.26720559366528},
  { id: 68, lon: 19.566969186755376, lat: 50.265553857257906},
  { id: 69, lon: 19.563215207432094, lat: 50.26470187594551},
  
  { id: 70, lon: 19.57384387221809, lat: 50.26582554317513},  // przystanek
  { id: 71, lon: 19.569579785650266, lat: 50.264484038859536},
  { id: 72, lon: 19.573127986882152, lat: 50.2698597017167}, // przystanek
  { id: 73, lon: 19.57322182430319, lat: 50.26460259231911},
  { id: 74, lon: 19.571500532852326, lat: 50.263628523705734},
  { id: 75, lon: 19.56287823500117, lat: 50.26073760908639},
  { id: 76, lon: 19.562886521599644, lat: 50.260638279350566},
  { id: 77, lon: 19.563111337640184, lat: 50.261493692395106},
  { id: 78, lon: 19.564375278309353, lat: 50.26307517826612},
  { id: 79, lon: 19.56915561126553, lat: 50.26074320373892},
  
  { id: 80, lon: 19.574437163027532, lat: 50.265675352313096},
  { id: 81, lon: 19.574532909345155, lat: 50.26560777484212},
  { id: 82, lon: 19.57452291882066, lat: 50.26504156164205},
  { id: 83, lon: 19.574562388256986, lat: 50.26474072230768},
  { id: 84, lon: 19.575711357429654, lat: 50.262101884304876}, // przystanek
  { id: 85, lon: 19.578443180740773, lat: 50.266859416452775}, // przystanek
  { id: 86, lon: 19.579534529492207, lat: 50.26733031792565},
  { id: 87, lon: 19.57984450693069, lat: 50.26911955960768},
  { id: 88, lon: 19.578131441088036, lat: 50.26533267179006},
  { id: 89, lon: 19.579483245199274, lat: 50.26358764847855},
  
  { id: 90, lon: 19.56778516124424, lat: 50.26048539215953}, // przystanek
  { id: 91, lon: 19.569602213593836, lat: 50.26043275380495}, // przystanek
  { id: 92, lon: 19.583986200981453, lat: 50.2704743664702}, // przystanek
  { id: 93, lon: 19.589004229783473, lat: 50.27127506202616}, // przystanek
  { id: 94, lon: 19.578999568007188, lat: 50.277703664833815}, // przystanek
  { id: 95, lon: 19.541864697196996, lat: 50.25730128907193},
  { id: 96, lon: 19.548236834450563, lat: 50.25682849833913},
  { id: 97, lon: 19.547459031713547, lat: 50.25404751047994},
  { id: 98, lon: 19.55527149783928, lat: 50.252777322227125},
  { id: 99, lon: 19.54132490380417, lat: 50.253203843896955},
  
  { id: 100, lon: 19.540509824422305, lat: 50.24758385267385},
  { id: 101, lon: 19.540073903504748, lat: 50.243793440387094},
  { id: 102, lon: 19.561286405656237, lat: 50.25149996738113}, // przystanek
  { id: 103, lon: 19.57686461825077, lat: 50.258819316753566}, // przystanek
  { id: 104, lon: 19.57660261977319, lat: 50.25338634072477}, // przystanek
  { id: 105, lon: 19.576630580301646, lat: 50.24966941671306},
  { id: 106, lon: 19.578098330318035, lat: 50.247926038011855}, // przystanek
  { id: 107, lon: 19.599617595572568, lat: 50.2453896064133},
  { id: 108, lon: 19.581107898280656, lat: 50.243735066501245},
  { id: 109, lon: 19.579206011580798, lat: 50.24565228628934},
  
  { id: 110, lon: 19.580095367803608, lat: 50.24226196815229},
  { id: 111, lon: 19.59717133134084, lat: 50.25788337232141},
  { id: 112, lon: 19.599079588584765, lat: 50.249347078268535},
  { id: 113, lon: 19.59981538799463, lat: 50.24518576585556}, // przystanek
  { id: 114, lon: 19.599753861511292, lat: 50.24411681141384},
  { id: 115, lon: 19.60176963110896, lat: 50.228840358090025},
  { id: 116, lon: 19.602752708935196, lat: 50.20673910358536}, // przystanek
  { id: 117, lon: 19.600774275101696, lat: 50.20649539876791},
  { id: 118, lon: 19.59550809556684, lat: 50.20567032162147},
  { id: 119, lon: 19.593827720690648, lat: 50.20773020132154},
  
  { id: 120, lon: 19.612044976144773, lat: 50.20863237319913},
  { id: 121, lon: 19.62426637709682, lat: 50.2068375098063}, // przystanek
  { id: 122, lon: 19.62734130240289, lat: 50.20644675631278},
  { id: 123, lon: 19.634225430015594, lat: 50.2059420098849},
  { id: 124, lon: 19.637074790876547, lat: 50.21777425073702},
  { id: 125, lon: 19.640644287498557, lat: 50.22267391400932}, // przystanek
  { id: 126, lon: 19.640480564748383, lat: 50.22368650173454},
  { id: 127, lon: 19.63901590373859, lat: 50.228685645755135},
  { id: 128, lon: 19.623651500386856, lat: 50.24880438949676},
  { id: 129, lon: 19.620179226971597, lat: 50.2477179340358}, // przystanek
  
  { id: 130, lon: 19.619963393158802, lat: 50.24773916774792},
  { id: 131, lon: 19.617664386732386, lat: 50.246896308047276},
  { id: 132, lon: 19.612133174699338, lat: 50.245144165340896},
  { id: 133, lon: 19.649955789741625, lat: 50.24764662264725}, // przystanek
  { id: 134, lon: 19.65011262751732, lat: 50.24774247793952},
  { id: 135, lon: 19.657394536942846, lat: 50.24738313816127},
  { id: 136, lon: 19.664355860991133, lat: 50.246906648740904}, // przystanek
  { id: 137, lon: 19.66462431321217, lat: 50.24686916949343},
  { id: 138, lon: 19.66831539696489, lat: 50.24663198798834}, // przystanek
  { id: 139, lon: 19.669809098595675, lat: 50.24639942366252}, // przystanek
  
  { id: 140, lon: 19.670798354501155, lat: 50.246150832710256},
  { id: 141, lon: 19.675460229282933, lat: 50.24316141739687}, // przystanek
  { id: 142, lon: 19.668744557560732, lat: 50.247131714478954}, // przystanek
  { id: 143, lon: 19.665327621965304, lat: 50.248975226190716},
  { id: 144, lon: 19.658613836141335, lat: 50.251022925494524}, // przystanek
  { id: 145, lon: 19.657997899281366, lat: 50.25136183350328}, // przystanek
  { id: 146, lon: 19.644634990845134, lat: 50.25480417864439},
  { id: 147, lon: 19.64339776095576, lat: 50.25528986533773},
  { id: 148, lon: 19.617029911667277, lat: 50.2633661500942},
  { id: 149, lon: 19.61278131709433, lat: 50.26692093735105},
  
  { id: 150, lon: 19.611578846413437, lat: 50.267523521008606}, // przystanek
  { id: 151, lon: 19.626225929662116, lat: 50.270131612791396}, // przystanek
  { id: 152, lon: 19.640817501824458, lat: 50.272039364923735}, // przystanek
  { id: 153, lon: 19.644369638452062, lat: 50.271605141282826},
  { id: 154, lon: 19.629002859073218, lat: 50.27383504196956},
  { id: 155, lon: 19.649284203732712, lat: 50.27172606244028},
  { id: 156, lon: 19.65689628614747, lat: 50.27173347834276},
  { id: 157, lon: 19.67260685089653, lat: 50.25978831949463},
  { id: 158, lon: 19.67573581315076, lat: 50.259272701168214},
  { id: 159, lon: 19.684401548138432, lat: 50.258174343776545},
  
  { id: 160, lon: 19.55704382912366, lat: 50.22104557736778},
  { id: 161, lon: 19.556704376738036, lat: 50.220028365706355}, // przystanek
  { id: 162, lon: 19.557843041406752, lat: 50.213399036163096},
  { id: 163, lon: 19.557388832591172, lat: 50.213020288858964}, // przystanek
  { id: 164, lon: 19.55722410302862, lat: 50.21283885337896},
  { id: 165, lon: 19.558826513127972, lat: 50.29155419866947}, // przystanek
  { id: 166, lon: 19.574561729290306, lat: 50.3002936753691},
  { id: 167, lon: 19.577233407987553, lat: 50.3048076018995},
  { id: 168, lon: 19.5771020209445, lat: 50.30768095463119},
  { id: 169, lon: 19.576792942211267, lat: 50.30952389882816},
  
  { id: 170, lon: 19.5763465291762, lat: 50.31476552236808},
  { id: 171, lon: 19.580675101839446, lat: 50.31315657801008},
  { id: 172, lon: 19.596034809845364, lat: 50.298867071827964},
  { id: 173, lon: 19.63998654821257, lat: 50.33427708489808},
  { id: 174, lon: 19.64249166875933, lat: 50.33544379086365},
  { id: 175, lon: 19.643434027588054, lat: 50.33542994211123}, // przystanek
  { id: 176, lon: 19.64746157902205, lat: 50.33215083584937}, // przystanek
  { id: 177, lon: 19.644483762752905, lat: 50.3229036891886},
  { id: 178, lon: 19.638802776568113, lat: 50.3197795346177},
  { id: 179, lon: 19.636115108625585, lat: 50.31821385190089},
  
  { id: 180, lon: 19.62968704097973, lat: 50.31313719995999},
  { id: 181, lon: 19.624615848923177, lat: 50.30871090373504},
  { id: 182, lon: 19.630814009870832, lat: 50.301275307617836},
  { id: 183, lon: 19.645124984774117, lat: 50.30273633029763},
  { id: 184, lon: 19.645516734336677, lat: 50.30282239458433}, // przystanek
  { id: 185, lon: 19.650938422596386, lat: 50.30556799320062},
  { id: 186, lon: 19.66183822362777, lat: 50.307938648057274},
  { id: 187, lon: 19.66438657893443, lat: 50.306214108447676},
  { id: 188, lon: 19.666525095069037, lat: 50.305683135569694},
  { id: 189, lon: 19.66434429951603, lat: 50.30783292781267},
  
  { id: 190, lon: 19.668678564813284, lat: 50.30654986674885},
  { id: 191, lon: 19.672813133086656, lat: 50.305700905321636},
  { id: 192, lon: 19.669989851842992, lat: 50.316661578054216},
  { id: 193, lon: 19.60629820716895, lat: 50.28054495832865},
  { id: 194, lon: 19.601350835342476, lat: 50.27969261944745}, // przystanek
  { id: 195, lon: 19.60066251354083, lat: 50.27891520376255}, // przystanek
  { id: 196, lon: 19.597426008935035, lat: 50.27504152621834}, // przystanek
  { id: 197, lon: 19.596600239512235, lat: 50.27436422397543} // przystanek
];  



 // tutaj style

 const styl_gm = 
 new Style({
   fill: new Fill({
     color: 'rgba(255, 0, 0, 0)', // Kolor wypełnienia z przezroczystością (4ta wartość 0 - czyli calkowita przezroczystosc)
   }),
   stroke: new Stroke({
     color: 'blue',
     width: 3,
   }),
   zIndex: 1
 });

 const styl_pow = 
 new Style({
   fill: new Fill({
     color: 'rgba(255, 0, 0, 0)', // Kolor wypełnienia z przezroczystością (4ta wartość 0 - czyli calkowita przezroczystosc)
   }),
   stroke: new Stroke({
     color: 'red',
     width: 3,
   }),
   zIndex: 2
 });

 const styl_woj = 
 new Style({
   fill: new Fill({
     color: 'rgba(255, 0, 0, 0)', // Kolor wypełnienia z przezroczystością (4ta wartość 0 - czyli calkowita przezroczystosc)
   }),
   stroke: new Stroke({
     color: 'green',
     width: 3,
   }),
   zIndex: 3
 });

 const styl_wybranej_gminy = 
 new Style({
   stroke: new Stroke({
     color: '#800000',
     width: 4,
   }),
   zIndex: 4
 });




 const MapComponent: React.FC<MapComponentProps> = ({ selectedOption }) => {

  const [map, setMap] = useState<Map | null>(null);

  const vectorSource1 = new VectorSource();


  useEffect(() => {

    const czernichowLonLat = [19.711382770231435, 50.03395691992472];
    const czernichowWebMercator = fromLonLat(czernichowLonLat);
    if (!map) {
      const newMap = new Map({
        target: 'map',
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: czernichowWebMercator,
          zoom: 8,
        }),
      });

      setMap(newMap);


        const imageContainer = document.createElement('div');
        imageContainer.style.position = 'absolute';
        imageContainer.style.bottom = '50px'; // Odległość od dołu mapy
        imageContainer.style.right = '50px'; // Odległość od prawego boku mapy    
      
        // Dodaj link do strony internetowej
        const linkElement = document.createElement('a');
        linkElement.href = 'http://www.exp101.net'; // Dodaj link do swojej strony
        linkElement.target = '_blank'; // Otwórz link w nowym oknie (lub możesz ustawić '_self' dla tego samego okna)
        linkElement.text = "www.exp101.net";
        linkElement.style.color = "red";
        linkElement.style.fontSize = "xx-large";

        // Dodaj obrazek i link do diva
        
        imageContainer.appendChild(linkElement);
    
        // Dodaj div do kontenera mapy
        newMap.getTargetElement().appendChild(imageContainer);

      }

        else {

                                    vectorSource1.clear();

          map.getLayers().forEach(layer => {
            if (layer instanceof VectorLayer) {
                map.removeLayer(layer);
            }
        });            
            const warstwy = map.getLayers();
            map.addLayer(new TileLayer({ source: new OSM() }));
            // odtąd granice gmin w powiecie krakowskim

            const geojsonSourceGm1 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Krzeszowice.geojson',
              
            });

            const geojsonLayerGm1 = new VectorLayer({
              source: geojsonSourceGm1,
              style: styl_gm,
            });
            const geojsonSourceGm2 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Czernichow.geojson',
            });
            const geojsonLayerGm2 = new VectorLayer({
              source: geojsonSourceGm2,
              style: styl_gm,
            });

            const geojsonSourceGm3 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Igolomia-Wawrzenczyce.geojson',
            });
          
            const geojsonLayerGm3 = new VectorLayer({
              source: geojsonSourceGm3,
              style: styl_gm,
            });
          
            const geojsonSourceGm4 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Iwanowice.geojson',
            });
          
            const geojsonLayerGm4 = new VectorLayer({
              source: geojsonSourceGm4,
              style: styl_gm,
            });
          
            const geojsonSourceGm5 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Jerzmanowice-Przeginia.geojson',
            });
          
            const geojsonLayerGm5 = new VectorLayer({
              source: geojsonSourceGm5,
              style: styl_gm,
            });
          
            const geojsonSourceGm6 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Kocmyrzow-Luborzyca.geojson',
            });
          
            const geojsonLayerGm6 = new VectorLayer({
              source: geojsonSourceGm6,
              style: styl_gm,
            });
          
            const geojsonSourceGm7 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Liszki.geojson',
            });
          
            const geojsonLayerGm7 = new VectorLayer({
              source: geojsonSourceGm7,
              style: styl_gm,
            });
          
            const geojsonSourceGm8 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Michalowice.geojson',
            });
          
            const geojsonLayerGm8 = new VectorLayer({
              source: geojsonSourceGm8,
              style: styl_gm,
            });
          
            const geojsonSourceGm9 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Mogilany.geojson',
            });
          
            const geojsonLayerGm9 = new VectorLayer({
              source: geojsonSourceGm9,
              style: styl_gm,
            });
          
            const geojsonSourceGm10 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Skala.geojson',
            });
          
            const geojsonLayerGm10 = new VectorLayer({
              source: geojsonSourceGm10,
              style: styl_gm,
            });
          
            const geojsonSourceGm11 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Slomniki.geojson',
            });
          
            const geojsonLayerGm11 = new VectorLayer({
              source: geojsonSourceGm11,
              style: styl_gm,
            });
          
            const geojsonSourceGm12 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Suloszowa.geojson',
            });
          
            const geojsonLayerGm12 = new VectorLayer({
              source: geojsonSourceGm12,
              style: styl_gm,
            });
          
            const geojsonSourceGm13 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Swiatniki_Gorne.geojson',
            });
          
            const geojsonLayerGm13 = new VectorLayer({
              source: geojsonSourceGm13,
              style: styl_gm,
            });
          
            const geojsonSourceGm14 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Wielka_Wies.geojson',
            });
          
            const geojsonLayerGm14 = new VectorLayer({
              source: geojsonSourceGm14,
              style: styl_gm,
            });
          
            const geojsonSourceGm15 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Zabierzow.geojson',
            });
          
            const geojsonLayerGm15 = new VectorLayer({
              source: geojsonSourceGm15,
              style: styl_gm,
            });
          
            const geojsonSourceGm16 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Zielonki.geojson',
            });
          
            const geojsonLayerGm16 = new VectorLayer({
              source: geojsonSourceGm16,
              style: styl_gm,
            });
          
            const geojsonSourceGm17 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/Skawina.geojson',
            });
          
            const geojsonLayerGm17 = new VectorLayer({
              source: geojsonSourceGm17,
              style: styl_gm,
            });

            map.addLayer(geojsonLayerGm1);
            map.addLayer(geojsonLayerGm2);
            map.addLayer(geojsonLayerGm3);
            map.addLayer(geojsonLayerGm4);
            map.addLayer(geojsonLayerGm5);
            map.addLayer(geojsonLayerGm6);
            map.addLayer(geojsonLayerGm7);
            map.addLayer(geojsonLayerGm8);
            map.addLayer(geojsonLayerGm9);
            map.addLayer(geojsonLayerGm10);
            map.addLayer(geojsonLayerGm11);
            map.addLayer(geojsonLayerGm12);
            map.addLayer(geojsonLayerGm13);
            map.addLayer(geojsonLayerGm14);
            map.addLayer(geojsonLayerGm15);
            map.addLayer(geojsonLayerGm16);
            map.addLayer(geojsonLayerGm17);        
            
            // odtąd granice gmin w powiecie nowosadeckim

            const geojsonSourceNowosadeckiGm1 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Chelmiec.geojson',
            });
          
            const geojsonLayerNowosadeckiGm1 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm1,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm2 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/GrodekNadDunajcem.geojson',
            });
          
            const geojsonLayerNowosadeckiGm2 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm2,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm3 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Grybow.geojson',
            });
          
            const geojsonLayerNowosadeckiGm3 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm3,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm4 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/KamionkaWielka.geojson',
            });
          
            const geojsonLayerNowosadeckiGm4 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm4,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm5 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Korzenna.geojson',
            });
          
            const geojsonLayerNowosadeckiGm5 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm5,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm6 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/KrynicaZdroj.geojson',
            });
          
            const geojsonLayerNowosadeckiGm6 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm6,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm7 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Labowa.geojson',
            });
          
            const geojsonLayerNowosadeckiGm7 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm7,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm8 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Lacko.geojson',
            });
          
            const geojsonLayerNowosadeckiGm8 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm8,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm9 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/LososinaDolna.geojson',
            });
          
            const geojsonLayerNowosadeckiGm9 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm9,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm10 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Muszyna.geojson',
            });
          
            const geojsonLayerNowosadeckiGm10 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm10,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm11 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Nawojowa.geojson',
            });
          
            const geojsonLayerNowosadeckiGm11 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm11,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm12 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/PiwnicznaZdroj.geojson',
            });
          
            const geojsonLayerNowosadeckiGm12 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm12,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm13 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Podegrodzie.geojson',
            });
          
            const geojsonLayerNowosadeckiGm13 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm13,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm14 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/Rytro.geojson',
            });
          
            const geojsonLayerNowosadeckiGm14 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm14,
              style: styl_gm,
            });
          
            const geojsonSourceNowosadeckiGm15 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/nowosadecki/StarySacz.geojson',
            });
          
            const geojsonLayerNowosadeckiGm15 = new VectorLayer({
              source: geojsonSourceNowosadeckiGm15,
              style: styl_gm,
            });

            const geojsonSourceOlkuskiGm1 = new VectorSource({
              format: new GeoJSON(),
              url: '/gminy/olkuski/Olkusz.geojson',
            });
            const geojsonLayerOlkuskiGm1 = new VectorLayer({
              source: geojsonSourceOlkuskiGm1,
              style: styl_gm,
            });

      map.addLayer(geojsonLayerNowosadeckiGm1);
      map.addLayer(geojsonLayerNowosadeckiGm2);
      map.addLayer(geojsonLayerNowosadeckiGm3);
      map.addLayer(geojsonLayerNowosadeckiGm4);
      map.addLayer(geojsonLayerNowosadeckiGm5);
      map.addLayer(geojsonLayerNowosadeckiGm6);
      map.addLayer(geojsonLayerNowosadeckiGm7);
      map.addLayer(geojsonLayerNowosadeckiGm8);
      map.addLayer(geojsonLayerNowosadeckiGm9);
      map.addLayer(geojsonLayerNowosadeckiGm10);
      map.addLayer(geojsonLayerNowosadeckiGm11);
      map.addLayer(geojsonLayerNowosadeckiGm12);
      map.addLayer(geojsonLayerNowosadeckiGm13);
      map.addLayer(geojsonLayerNowosadeckiGm14);
      map.addLayer(geojsonLayerNowosadeckiGm15);

      map.addLayer(geojsonLayerOlkuskiGm1);


            // tutaj województwa:

  const geojsonSourceWoj1 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/malopolskie.geojson',
  });

  const geojsonLayerWoj1 = new VectorLayer({
    source: geojsonSourceWoj1,
    style: styl_woj
  });

  const geojsonSourceWoj2 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/dolnoslaskie.geojson',
  });

  const geojsonLayerWoj2 = new VectorLayer({
    source: geojsonSourceWoj2,
    style: styl_woj
  });

  const geojsonSourceWoj3 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/kujawsko-pomorskie.geojson',
  });

  const geojsonLayerWoj3 = new VectorLayer({
    source: geojsonSourceWoj3,
    style: styl_woj
  });

  const geojsonSourceWoj4 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/lodzkie.geojson',
  });

  const geojsonLayerWoj4 = new VectorLayer({
    source: geojsonSourceWoj4,
    style: styl_woj
  });

  const geojsonSourceWoj5 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/lubelskie.geojson',
  });

  const geojsonLayerWoj5 = new VectorLayer({
    source: geojsonSourceWoj5,
    style: styl_woj
  });

  const geojsonSourceWoj6 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/lubuskie.geojson',
  });

  const geojsonLayerWoj6 = new VectorLayer({
    source: geojsonSourceWoj6,
    style: styl_woj
  });

  const geojsonSourceWoj7 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/mazowieckie.geojson',
  });

  const geojsonLayerWoj7 = new VectorLayer({
    source: geojsonSourceWoj7,
    style: styl_woj
  });

  const geojsonSourceWoj8 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/opolskie.geojson',
  });

  const geojsonLayerWoj8 = new VectorLayer({
    source: geojsonSourceWoj8,
    style: styl_woj
  });

  const geojsonSourceWoj9 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/podkarpackie.geojson',
  });

  const geojsonLayerWoj9 = new VectorLayer({
    source: geojsonSourceWoj9,
    style: styl_woj
  });

  const geojsonSourceWoj10 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/podlaskie.geojson',
  });

  const geojsonLayerWoj10 = new VectorLayer({
    source: geojsonSourceWoj10,
    style: styl_woj
  });

  const geojsonSourceWoj11 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/pomorskie.geojson',
  });

  const geojsonLayerWoj11 = new VectorLayer({
    source: geojsonSourceWoj11,
    style: styl_woj
  });

  const geojsonSourceWoj12 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/slaskie.geojson',
  });

  const geojsonLayerWoj12 = new VectorLayer({
    source: geojsonSourceWoj12,
    style: styl_woj
  });

  const geojsonSourceWoj13 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/swietokrzyskie.geojson',
  });

  const geojsonLayerWoj13 = new VectorLayer({
    source: geojsonSourceWoj13,
    style: styl_woj
  });

  const geojsonSourceWoj14 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/warminsko-mazurskie.geojson',
  });

  const geojsonLayerWoj14 = new VectorLayer({
    source: geojsonSourceWoj14,
    style: styl_woj
  });

  const geojsonSourceWoj15 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/wielkopolskie.geojson',
  });

  const geojsonLayerWoj15 = new VectorLayer({
    source: geojsonSourceWoj15,
    style: styl_woj
  });

  const geojsonSourceWoj16 = new VectorSource({
    format: new GeoJSON(),
    url: '/wojewodztwa/zachodniopomorskie.geojson',
  });

  const geojsonLayerWoj16 = new VectorLayer({
    source: geojsonSourceWoj16,
    style: styl_woj
  });
  map.addLayer(geojsonLayerWoj1);
  map.addLayer(geojsonLayerWoj2);
  map.addLayer(geojsonLayerWoj3);
  map.addLayer(geojsonLayerWoj4);
  map.addLayer(geojsonLayerWoj5);
  map.addLayer(geojsonLayerWoj6);
  map.addLayer(geojsonLayerWoj7);
  map.addLayer(geojsonLayerWoj8);
  map.addLayer(geojsonLayerWoj9);
  map.addLayer(geojsonLayerWoj10);
  map.addLayer(geojsonLayerWoj11);
  map.addLayer(geojsonLayerWoj12);
  map.addLayer(geojsonLayerWoj13);
  map.addLayer(geojsonLayerWoj14);
  map.addLayer(geojsonLayerWoj15);
  map.addLayer(geojsonLayerWoj16);
          
// tutaj powiaty z woj. małopolskiego:

const geojsonSourcePow1 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/krakowski.geojson',
});

const geojsonLayerPow1 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow1
    
});
const geojsonSourcePow2 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/bochenski.geojson',
});

const geojsonLayerPow2 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow2
    
});

const geojsonSourcePow3 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/brzeski.geojson',
});

const geojsonLayerPow3 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow3
    
});

const geojsonSourcePow4 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/chrzanowski.geojson',
});

const geojsonLayerPow4 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow4
    
});

const geojsonSourcePow5 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/dabrowski.geojson',
});

const geojsonLayerPow5 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow5
    
});

const geojsonSourcePow6 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/gorlicki.geojson',
});

const geojsonLayerPow6 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow6
    
});

const geojsonSourcePow7 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/Krakow.geojson',
});

const geojsonLayerPow7 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow7
    
});

const geojsonSourcePow8 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/limanowski.geojson',
});

const geojsonLayerPow8 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow8
    
});

const geojsonSourcePow9 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/miechowski.geojson',
});

const geojsonLayerPow9 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow9
    
});

const geojsonSourcePow10 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/myslenicki.geojson',
});

const geojsonLayerPow10 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow10
    
});

const geojsonSourcePow11 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/nowosadecki.geojson',
});

const geojsonLayerPow11 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow11
    
});

const geojsonSourcePow12 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/nowotarski.geojson',
});

const geojsonLayerPow12 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow12
    
});

const geojsonSourcePow13 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/Nowy_Sacz.geojson',
});

const geojsonLayerPow13 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow13
    
});

const geojsonSourcePow14 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/olkuski.geojson',
});

const geojsonLayerPow14 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow14
    
});

const geojsonSourcePow15 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/oswiecimski.geojson',
});

const geojsonLayerPow15 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow15
    
});

const geojsonSourcePow16 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/proszowicki.geojson',
});

const geojsonLayerPow16 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow16
    
});

const geojsonSourcePow17 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/suski.geojson',
});

const geojsonLayerPow17 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow17
    
});

const geojsonSourcePow18 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/Tarnow.geojson',
});

const geojsonLayerPow18 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow18
    
});

const geojsonSourcePow19 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/tarnowski.geojson',
});

const geojsonLayerPow19 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow19
    
});

const geojsonSourcePow20 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/tatrzanski.geojson',
});

const geojsonLayerPow20 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow20
    
});

const geojsonSourcePow21 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/wadowicki.geojson',
});

const geojsonLayerPow21 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow21
    
});

const geojsonSourcePow22 = new VectorSource({
  format: new GeoJSON(), // kluczowe!!! wtedy można ustawić opacity
  url: 'powiaty/wielicki.geojson',
});

const geojsonLayerPow22 = new VectorLayer({
    style: styl_pow,
    source: geojsonSourcePow22
    
});


      map.addLayer(geojsonLayerPow1);
      map.addLayer(geojsonLayerPow2);
      map.addLayer(geojsonLayerPow3);
      map.addLayer(geojsonLayerPow4);
      map.addLayer(geojsonLayerPow5);
      map.addLayer(geojsonLayerPow6);
      map.addLayer(geojsonLayerPow7);
      map.addLayer(geojsonLayerPow8);
      map.addLayer(geojsonLayerPow9);
      map.addLayer(geojsonLayerPow10);
      map.addLayer(geojsonLayerPow11);
      map.addLayer(geojsonLayerPow12);
      map.addLayer(geojsonLayerPow13);
      map.addLayer(geojsonLayerPow14);
      map.addLayer(geojsonLayerPow15);
      map.addLayer(geojsonLayerPow16);
      map.addLayer(geojsonLayerPow17);
      map.addLayer(geojsonLayerPow18);
      map.addLayer(geojsonLayerPow19);
      map.addLayer(geojsonLayerPow20);
      map.addLayer(geojsonLayerPow21);
      map.addLayer(geojsonLayerPow22);

      let markers: { id: number; lon: number; lat: number }[] = [];
        let geojsonSource: VectorSource | null = null;

        switch (selectedOption) {
            case 'gmina1':
                geojsonSource = geojsonSourceGm2;
                markers = pointsCzern;
                break;
            case 'gmina2':
                geojsonSource = geojsonSourceGm1;
                markers = pointsKrzesz;
                break;
            case 'gmina3':
                geojsonSource = geojsonSourceNowosadeckiGm9;
                markers = pointsLosos;
                break;
            case 'gmina4':
                geojsonSource = geojsonSourceGm5;
                markers = pointsJerz;
                break;
            case 'gmina5':
                geojsonSource = geojsonSourceGm15;
                markers = pointsZabierzow;
                break;    
            case 'gmina6':
                geojsonSource = geojsonSourceOlkuskiGm1;
                markers = pointsOlkusz;
                break;        
            default:
                geojsonSource = geojsonSourceGm2;
                markers = pointsCzern;
        }

        if (geojsonSource) {
            geojsonSource.once('change', () => {
                if (geojsonSource && geojsonSource.getState() === 'ready') {
                    const extent = geojsonSource.getExtent();
                    if (extent) {
                        const view = map.getView();
                        const mapSize = map.getSize();

                        const extraPaddingPercent = 0.15; // 15% dodatkowego odstępu
                        const extraPadding = [
                            (extent[2] - extent[0]) * extraPaddingPercent, // left
                            (extent[3] - extent[1]) * extraPaddingPercent, // bottom
                            (extent[2] - extent[0]) * extraPaddingPercent, // right
                            (extent[3] - extent[1]) * extraPaddingPercent  // top
                        ];

                        const extentWithPadding = [
                            extent[0] - extraPadding[0], // left
                            extent[1] - extraPadding[1], // bottom
                            extent[2] + extraPadding[2], // right
                            extent[3] + extraPadding[3]  // top
                        ];

                        view.fit(extentWithPadding, { size: mapSize });
                      }
                    }} ) }



                          // Dodanie markerów z nową skalą
     
                          const vectorSource = new VectorSource();
                          map.on('moveend', function() {
                            vectorSource.clear(); // Wyczyść wszystkie stare markery przed dodaniem nowych
                      
                            const view = map.getView(); // Pobierz widok mapy
                      
                        if (view) {
                          const zoom = view.getZoom();
                          if (zoom !== undefined) { // Sprawdź, czy zoom nie jest undefined
                            let scale: number; // Zadeklaruj typ zmiennej scale jako number
                            if (zoom <= 10){
                              scale =  zoom/40 //0.125 
                             }
                             else if (zoom > 10 && zoom<=12){
                              scale = zoom/20 // 0.3
                             }
                             else if (zoom > 12 && zoom<=14){
                              scale = 1 //0.7
                             }
                             else{
                              scale = 1
                             }
                      
                            markers.forEach(point => {
                              const feature1 = new Feature({
                                  geometry: new Point(fromLonLat([point.lon, point.lat])),
                                id: point.id,
                              });
                              feature1.setStyle(
                                new Style({
                                  image: new Icon({
                                    anchor: [0.5, 1],
                                    src: 'ikona.png', // Ścieżka do pliku ikony
                                    scale: scale, // Użyj zmiennej scale jako wartości skali
                                  }),
                                })
                              );
                              vectorSource.addFeature(feature1);
                            });
                          }
                        }
                      });
                      
                      const vectorLayer = new VectorLayer({
                        source: vectorSource,
                      }); 
                        map.addLayer(vectorLayer);
                  
              }
    


      
    }, [map, selectedOption]);
    
  return (
    <>
        <div id="map" className="map" style={{ width: '100%', height: '100vh' }} />
    </>
  );
};

export default MapComponent;

