import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Hidden} from '@mui/material';




interface NavbarProps {
  onOptionChange: (option: string) => void; // Deklaracja funkcji, która będzie wywołana przy zmianie opcji
}

const NavbarComponent: React.FC<NavbarProps> = ({ onOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState<string>(''); // Ustaw typ jako string

  const handleSelectChange = (event: SelectChangeEvent<string>) => { // Zmiana typu zdarzenia
    const value = event.target.value;
    setSelectedOption(value);
    onOptionChange(value); // Wywołuje funkcję przekazaną z App.tsx z nową opcją
  };
 

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'white' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ minWidth: 170 }}>
            <FormControl sx={{ m: 1, backgroundColor: 'white', display: 'flex', color: 'darkgreen', marginRight: '3px' }}>
              <InputLabel id="demo-simple-select-autowidth-label" sx={{ color: 'darkgreen' }}>Wybierz gminę</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedOption}
                label="Wybierz gminę"
                onChange={handleSelectChange}
                autoWidth
                sx={{ color: 'darkgreen' }}
              >
                <MenuItem value={'gmina1'}>Czernichów</MenuItem>
                <MenuItem value={'gmina2'}>Krzeszowice</MenuItem>
                <MenuItem value={'gmina3'}>Łososina Dolna</MenuItem>
                <MenuItem value={'gmina4'}>Jerzmanowice-Przeginia</MenuItem>
                <MenuItem value={'gmina5'}>Zabierzów</MenuItem>
                <MenuItem value={'gmina6'}>Olkusz</MenuItem>



              </Select>
            </FormControl>
          </Box>
          <Hidden mdDown>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: 'darkgreen', textAlign: 'center' }}>
              Bieżący projekt - mapy gmin
            </Typography>
          </Hidden>
          <Box sx={{ justifyContent: 'center', alignItems: 'center'}}>
            <Button sx={{ fontSize: '0.7rem' }}> {/* Zmniejszenie wielkości tekstowej i pola marginesowego przycisku */}
              <a
                href='/strona_oferta.html'
                target='_blank'
                style={{
                  textDecoration: 'none',
                  backgroundColor: 'inherit',
                  color: 'darkgreen',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: 'darkgreen',
                  borderRadius: '5px',
                  padding: '4px 8px' 
                }}
              >O projekcie / Oferta</a>
            </Button>
          </Box>
          <Hidden mdDown>
            <Typography component="div" sx={{ fontSize: '18px', flexGrow: 1, color: 'black', textAlign: 'center' }}>
              ver.1.0.8
            </Typography>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );

};

export default NavbarComponent;
