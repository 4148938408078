import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Import Helmet i HelmetProvider


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
  <Helmet>
    <meta charSet="utf-8" />
    <title>exp101</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" /> {/* Dodaj meta viewport tag */}
  </Helmet>
  <App />
</HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
