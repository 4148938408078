import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Snackbar } from '@mui/material';

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [showBanner, setShowBanner] = useState(!cookies.cookieConsent);


  const handleAccept = () => {
    setCookie('cookieConsent', true, { path: '/' });
    setShowBanner(false);
  };

  return (
    <>
    <Snackbar
      open={showBanner}
      message="Ta strona używa plików cookie."
      action={
        <>
          <Button style={{ color: 'darkgreen', fontSize: '18px'}} size="medium" onClick={handleAccept}>
            OK
          </Button>
          <Button style={{ color: 'darkgreen', fontSize: '18px' }} size="medium" href="/cookies_policy.html" target="_blank" >
            Więcej
          </Button>
        </>
      }
      ContentProps={{
        style: {
          backgroundColor: 'white',
          color: 'darkgreen',
          border: '2px solid darkgreen',
          fontWeight: '600',
          fontSize: '18px',
        },
      }}
    />
    </>
  );
};

export default CookieBanner;
